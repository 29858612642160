import { format } from 'date-fns'

export default {
  methods: {
    formatDate(timestamp) {
      return format(new Date(timestamp), 'dd.MM.yyyy HH:mm:ss')
    },
    getStatusColor(string) {
      const key = (string || '').toLowerCase().trim()
      switch (key) {
        case 'deal_open':
        case 'money_sent':
          return 'warning'
        case 'dispute_open':
          return 'primary'
        case 'deal_completed':
          return 'success'
        case 'deal_canceled':
        case 'time_over':
          return 'danger'
        default:
          return 'primary'
      }
    },
    getStatusName(string) {
      const key = (string || '').toLowerCase().trim()
      switch (key) {
        case 'deal_open':
          return 'Deal open'
        case 'money_sent':
          return 'Money sent'
        case 'dispute_open':
          return 'Dispute open'
        case 'deal_completed':
          return 'Completed'
        case 'deal_canceled':
          return 'Canceled'
        case 'time_over':
          return 'Time over'
        default:
          return key
      }
    },
  },
};