export default [
  // {
  //   label: '№',
  //   field: 'number',
  // },
  {
    label: 'Deal ID',
    field: 'id',
  },
  {
    label: 'Data Created',
    field: 'dataCreated',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Responsible',
    field: 'responsible',
  },
  {
    label: 'Buyer',
    field: 'buyer',
  },
  {
    label: 'Seller',
    field: 'seller',
  },
  {
    label: 'Amount',
    field: 'amount',
  },
  {
    label: 'Payment method',
    field: 'paymentMethod',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
