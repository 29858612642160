<template>
  <div class="deals-table">
    <b-card no-body>
      <b-card-body class="">
        <b-card-title class="mb-3">
          Deal Statistics
        </b-card-title>
        <b-row>
          <!-- <b-col md="3" class="d-flex align-items-center my-1">
            <b-avatar class="mr-1" :variant="`light-primary`" size="48">
              <feather-icon size="21" icon="FileTextIcon" />
            </b-avatar>
            <div>
              <h4 class="font-weight-bolder mb-0" v-text="dealsStatistic.total || 0" />
              <b-card-text class="font-small-3 mb-0" v-text="`Total`" />
            </div>
          </b-col> -->
          <b-col sm="6" md="2" class="d-flex align-items-center my-1 cursor-pointer" @click="changeStatusStitistic('deal_completed')">
            <b-avatar class="mr-1" :variant="`light-success`" size="48">
              <feather-icon size="21" icon="CheckCircleIcon" />
            </b-avatar>
            <div>
              <h4 class="font-weight-bolder mb-0" v-text="dealsStatistic.deal_completed || 0" />
              <b-card-text class="font-small-3 mb-0" v-text="`Completed`" />
            </div>
          </b-col>
          <b-col sm="6" md="2" class="d-flex align-items-center my-1 cursor-pointer" @click="changeStatusStitistic('deal_open')">
            <b-avatar class="mr-1" :variant="`light-warning`" size="48">
              <feather-icon size="21" icon="ClockIcon" />
            </b-avatar>
            <div>
              <h4 class="font-weight-bolder mb-0" v-text="dealsStatistic.deal_open || 0" />
              <b-card-text class="font-small-3 mb-0" v-text="`Deal open`" />
            </div>
          </b-col>
          <b-col sm="6" md="2" class="d-flex align-items-center my-1 cursor-pointer" @click="changeStatusStitistic('money_sent')">
            <b-avatar class="mr-1" :variant="`light-warning`" size="48">
              <feather-icon size="21" icon="ClockIcon" />
            </b-avatar>
            <div>
              <h4 class="font-weight-bolder mb-0" v-text="dealsStatistic.money_sent || 0" />
              <b-card-text class="font-small-3 mb-0" v-text="`Money sent`" />
            </div>
          </b-col>
          <b-col sm="6" md="2" class="d-flex align-items-center my-1 cursor-pointer" @click="changeStatusStitistic('dispute_open')">
            <b-avatar class="mr-1" :variant="`light-warning`" size="48">
              <feather-icon size="21" icon="ClockIcon" />
            </b-avatar>
            <div>
              <h4 class="font-weight-bolder mb-0" v-text="dealsStatistic.dispute_open || 0" />
              <b-card-text class="font-small-3 mb-0" v-text="`Dispute open`" />
            </div>
          </b-col>
          <b-col sm="6" md="2" class="d-flex align-items-center my-1 cursor-pointer" @click="changeStatusStitistic('deal_canceled')">
            <b-avatar class="mr-1" :variant="`light-danger`" size="48">
              <feather-icon size="21" icon="XCircleIcon" />
            </b-avatar>
            <div>
              <h4 class="font-weight-bolder mb-0" v-text="dealsStatistic.deal_canceled || 0" />
              <b-card-text class="font-small-3 mb-0" v-text="`Canceled`" />
            </div>
          </b-col>
          <b-col sm="6" md="2" class="d-flex align-items-center my-1 cursor-pointer" @click="changeStatusStitistic('time_over')">
            <b-avatar class="mr-1" :variant="`light-danger`" size="48">
              <feather-icon size="21" icon="XCircleIcon" />
            </b-avatar>
            <div>
              <h4 class="font-weight-bolder mb-0" v-text="dealsStatistic.time_over || 0" />
              <b-card-text class="font-small-3 mb-0" v-text="`Time over`" />
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <AppTable
      :rows="deals.value"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: deals.limit,
        count: deals.count,
        page: deals.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col md="4" xl="4" class="mb-1">
            <b-form-group label="Select status" label-for="status-active">
              <v-select
                id="status-active"
                v-model="statusActive"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                placeholder="Select status"
                :options="statusOptions"
                :reduce="option => option.value"
                @input="changeStatus"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" xl="4" class="mb-1">
            <b-form-group label="Select responsible" label-for="responsible-select">
              <v-select
                id="responsible-select"
                v-model="responsible"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select responsible"
                :options="responsibleOptions"
                :reduce="option => option.id"
                @input="changeResponsible"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" xl="4" class="mb-1">
            <b-form-group label="Sort" label-for="sort-select">
              <v-select
                id="sort-select"
                v-model="sortActive"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                placeholder="Sort"
                :options="sortOptions"
                :reduce="option => option.value"
                @input="changeSort"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col md="4" xl="3" class="mb-1">
            <b-form-group label="Range date" label-for="datepicker-range">
              <flat-pickr
                id="datepicker-range"
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range'}"
              />
            </b-form-group>
          </b-col> -->

          <b-col md="4" xl="3" class="mb-1">
            <b-form-group label="Start date" label-for="datepicker-dateFrom">
              <b-form-datepicker
                id="datepicker-dateFrom"
                v-model="dateFrom"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                placeholder="Choose a min date"
                local="en"
                today-button
                reset-button
                close-button
                @input="changeDateFrom"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" xl="3" class="mb-1">
            <b-form-group label="End date" label-for="datepicker-dateTo">
              <b-form-datepicker
                id="datepicker-dateTo"
                v-model="dateTo"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                placeholder="Choose a max date"
                local="en"
                today-button
                reset-button
                close-button
                @input="changeDateTo"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="orderId" md="4" xl="2" class="mb-1">
            <b-form-group>
              <label class="mr-1">Order ID</label>
              <b-form-input v-model="orderId" placeholder="Order ID" type="number" @blur="changeOrderId" />
            </b-form-group>
          </b-col>

          <b-col class="mb-1">
            <b-form-group>
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="Buyer/Seller email" type="text" />
            </b-form-group>
          </b-col>

          <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow} = {}">
        <TableRow :row="row" :column="column" :formatted-row="formattedRow" />

        <span v-if="column.field === 'status'" class="flex-center-align">
          <b-badge :variant="getStatusColor(row.status)" class="text-white">
            {{ getStatusName(row.status) || '' }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'responsible'" class="flex-center-align">
          {{ row.responsible ? row.responsible.name : '' }}
        </span>

        <span v-else-if="column.field === 'buyer'" class="flex-center-align">
          <b-button v-if="row.buyer.firstName || row.buyer.lastName"  variant="link" @click="toUser(row.buyer.id)" target="_blank" class="text-left">
            {{ `${row.buyer.firstName || ''} ${row.buyer.lastName || ''}` }}
          </b-button>
          <b-button v-else  variant="link" @click="toUser(row.buyer.id)" target="_blank" class="text-left">
            {{ row.buyer.telegram_username || row.buyer.name }}
          </b-button>
        </span>

        <span v-else-if="column.field === 'seller'" class="flex-center-align">
          <b-button v-if="row.seller.firstName || row.seller.lastName"  variant="link" @click="toUser(row.seller.id)" target="_blank" class="text-left">
            {{ `${row.seller.firstName || ''} ${row.seller.lastName || ''}` }}
          </b-button>
          <b-button v-else  variant="link" @click="toUser(row.seller.id)" target="_blank" class="text-left">
            {{ row.seller.telegram_username || row.seller.name }}
          </b-button>
        </span>

        <span v-else-if="column.field === 'amount'" class="flex-center-align">
          {{ `${$decimal(row.amount).toDecimalPlaces(8)} ${row.fiat.code}` }}
        </span>

        <span v-else-if="column.field === 'paymentMethod'" class="flex-center-align">
          {{ row.paymentMethod ? row.paymentMethod.name : '' }}
        </span>

        <span v-else-if="column.field === 'dataCreated'" class="flex-center-align">
          {{ formatDate(row.createdAt) || '' }}
        </span>

        <span v-else-if="column.field === 'action'" class="d-flex justify-content-end">
          <div v-if="row.status === 'dispute_open'">
            <b-button
              v-if="row.responsible && userInfo.id === row.responsible.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-auto mr-1"
              type="button"
              variant="primary"
              @click="toChat(row)"
            >
              <span class="text-white" v-text="`To chat`" />
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-auto mr-1"
              type="button"
              variant="primary"
              :disabled="!!row.responsible"
              @click="handleDealChat(row)"
            >
              <span class="no-wrap text-white" v-text="'Join the chat'" />
            </b-button>
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mt-auto"
            type="button"
            variant="outline-primary"
            @click="toInfo(row)"
          >
            <span class="text-primary" v-text="'Details'" />
          </b-button>
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BBadge,
  BCard,
  BAvatar,
  BCardText,
  BCardBody,
  BCardTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import TableRow from '@/components/TableRow.vue'
import AppTable from '@/components/AppTable.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import mixinDeals from './mixins/mixinDeals'
import { statusOptions, sortOptions } from './config/selectOptions'

export default {
  components: {
    AppTable,
    TableRow,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BCardText,
    BCard,
    BCardBody,
    BCardTitle,
    BAvatar,
    vSelect,
    flatPickr
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, mixinDeals],
  data() {
    return {
      columns,
      currenPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],

      rangeDate: '',
      rangeDateFrom: '',
      rangeDateTo: '',

      searchTerm: '',
      dateFrom: '',
      dateTo: '',

      orderId: '',

      statusActive: '',
      responsible: '',
      sortActive: 'DESC',
      modalChange: '',
    }
  },

  computed: {
    ...mapGetters({
      deals: 'deals/deals',
      responsibleOptions: 'deals/responsibles',
      dealsStatistic: 'deals/dealsStatistic',
      userInfo: 'auth/admin',
    }),
    statusOptions() { return statusOptions },
    sortOptions() { return sortOptions },
  },
  mounted() {
    if (this.$route.query.orderId) this.orderId = this.$route.query.orderId
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchListDeals: 'deals/fetchListDeals',
      fetchResponsibles: 'deals/fetchResponsibles',
      fetchDealsStatistic: 'deals/fetchDealsStatistic',
      addModerator: 'deals/addModerator',
    }),

    createQueryConfig(config) {
      return {
        page: this.deals.page,
        limit: this.deals.limit,

        search: this.searchTerm || undefined,
        dateFrom: this.dateFrom || undefined,
        dateTo: this.dateTo || undefined,
        status: this.statusActive || undefined,
        responsibleId: this.responsible || undefined,
        sortOrder: this.sortActive || undefined,
        orderId: this.orderId || undefined,

        ...config,
      }
    },

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })

      this.fetchDealsStatistic()
      this.fetchResponsibles()
      this.fetchDeals(query)
    },

    search() {
      const query = this.createQueryConfig({ search: this.searchTerm, page: 1 })
      return this.fetchDeals(query)
    },
    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })
      return this.fetchDeals(query)
    },
    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })
      return this.fetchDeals(query)
    },
    changeCoin(coin) {
      const query = this.createQueryConfig({ coin })
      return this.fetchDeals(query)
    },
    changeResponsible(responsible) {
      const query = this.createQueryConfig({ responsibleId: responsible })
      return this.fetchDeals(query)
    },
    changeStatus(status) {
      const query = this.createQueryConfig({ status })
      return this.fetchDeals(query)
    },
    changeSort(sort) {
      const query = this.createQueryConfig({ sortOrder: sort })
      return this.fetchDeals(query)
    },
    changeOrderId() {
      const query = this.createQueryConfig({ orderId: this.orderId })
      return this.fetchDeals(query)
    },
    changeDateFrom() {
      const query = this.createQueryConfig({ dateFrom: this.dateFrom })
      return this.fetchDeals(query)
    },
    changeDateTo() {
      const query = this.createQueryConfig({ dateTo: this.dateTo })
      return this.fetchDeals(query)
    },

    fetchDeals(query) {
      return this.waitRequest(() => this.fetchListDeals(query).catch(this.checkErrors))
    },
    changeStatusStitistic(type) {
      this.statusActive = type
      this.changeStatus(type)
    },

    handleDealChat(row) {
      if (this.responsible) {
        return this.$router.push(`/p2p-deal/info/${row.id}`)
      }
      this.showMsgBoxTwo(row)
    },

    toInfo(row) {
      this.$router.push(`/p2p-deal/info/${row.id}`)
    },
    toChat(row) {
      this.$router.push(`/p2p-deal/chat/${row.id}`)
    },
    toUser(id) {
      this.$router.push(`/user/${id}`)
    },

    showMsgBoxTwo(row) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Please note that this dispute is reserved for you. You need to process this dispute, make a decision in favor of the buyer or seller and close the deal.', {
          title: 'Join the chat',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Join the chat',
          cancelTitle: 'Back',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.add(row)
          }
        })
    },
    add(row) {
      this.waitRequest(() => {
        return this.addModerator({ dealId: +row.id })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Moderator is added',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.toChat(row)
          })
          .catch(this.checkErrors)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.deals-table {
  .no-wrap {
    white-space: nowrap;
  }
}
</style>