export const statusOptions = [
  {
    value: 'deal_open',
    label: 'Deal open',
  },
  {
    value: 'money_sent',
    label: 'Money sent',
  },
  {
    value: 'dispute_open',
    label: 'Disput open',
  },
  {
    value: 'deal_canceled',
    label: 'Canceled',
  },
  {
    value: 'deal_completed',
    label: 'Completed',
  },
  {
    value: 'time_over',
    label: 'Time over',
  },
]

export const sortOptions = [
  {
    value: 'ASC',
    label: 'ASC',
  },
  {
    value: 'DESC',
    label: 'DESC',
  },
]