<template>
  <!--  <span v-if="column.field === 'status'" class="flex-center-align">-->
  <!--    <b-badge :variant="getStatusTheme(row.status)" v-once>-->
  <!--      {{ getStatusText(row.status) }}-->
  <!--    </b-badge>-->
  <!--  </span>-->

  <!--  <span v-if="column.field === 'isApproved'" class="flex-center-align">-->
  <!--    <b-badge :variant="getIsApprovedStatusTheme(row.isApproved)" v-once>-->
  <!--      {{ getIsApprovedStatusText(row.isApproved) }}-->
  <!--    </b-badge>-->
  <!--  </span>-->

  <span v-if="column.field === 'createdAt' && row.createdAt" v-once class="flex-center-align">
    {{ formattedDate(row.createdAt) }}
  </span>

  <span v-else-if="column.field === 'lastActivity' && row.lastActivity" v-once class="flex-center-align">
    {{ formattedDate(row.lastActivity) }}
  </span>

  <span v-else-if="column.field === 'paidCoin.name' && row.paidCoin" class="flex-center-align">
    {{ row.paidCoin.emoji }} {{ row.paidCoin.name }}
  </span>

  <span v-else-if="column.field === 'receivedCoin.name' && row.receivedCoin" class="flex-center-align">
    {{ row.receivedCoin.emoji }} {{ row.receivedCoin.name }}
  </span>

  <!--  &lt;!&ndash; Column: Common &ndash;&gt;-->
  <!--  <span v-else class="flex-center-align">-->
  <!--    {{ formattedRow[column.field] }}-->
  <!--  </span>-->
</template>
<script>
import { BBadge } from 'bootstrap-vue'
import { formattedDate } from '@/tools/util'

export default {
  name: 'TableRow',
  components: {
    BBadge,
  },
  props: {
    formattedRow: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formattedDate,
    statusVariant(
      status,
      newParam = {
        pending: {
          theme: 'light-danger',
          text: 'pending',
        },
        success: {
          theme: 'light-success',
          text: 'success',
        },
      },
    ) {
      const statusConfig = newParam

      return statusConfig[status]
    },

    statusIsApproved(status) {
      const isApproved = status ? 'approved' : 'reject'
      const statusConfig = {
        approved: {
          theme: 'light-success',
          text: 'Approved',
        },
        reject: {
          theme: 'light-danger',
          text: 'Not Active',
        },
      }

      return statusConfig[isApproved]
    },

    getIsApprovedStatusText(status) {
      const currentStatus = this.statusIsApproved(status)
      return currentStatus.text
    },
    getIsApprovedStatusTheme(status) {
      const currentStatus = this.statusIsApproved(status)
      return currentStatus.theme
    },

    getStatusText(status) {
      const currentStatus = this.statusVariant(status)
      return currentStatus.text
    },
    getStatusTheme(status) {
      const currentStatus = this.statusVariant(status)
      return currentStatus.theme
    },
  },
}
</script>

<style lang="scss">
.flex-center-align {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%; /* Убедитесь, что ячейка имеет определенную высоту */
}
</style>
